import * as React from 'react';
import { IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import * as clipboard from 'clipboard-polyfill/text';

interface ShareUserButtonProps {
}

const ShareUserButton: React.FunctionComponent<ShareUserButtonProps> = () => {
  const saveToClipboard = async () => {
    try {
      await clipboard.writeText(window.location.href);
      // TODO: Display success in a notistack
    } catch (e) {
      // TODO: Display error in a notistack
    }
  };

  return (
    <>
      <IconButton color="inherit" size="medium" aria-label="share" onClick={saveToClipboard}>
        <ShareIcon />
      </IconButton>
    </>
  );
};

export default ShareUserButton;
