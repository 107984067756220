import { User } from '@firebase/auth';
import {
  collection,
  addDoc,
  onSnapshot,
  Unsubscribe,
  doc,
  deleteDoc,
  updateDoc,
} from '@firebase/firestore';
import ItemDetails from '../components/WishlistPage/ItemDetails.model';
import { db } from './firebase/firestore';

export const saveItem = async (user: User, item: ItemDetails) => {
  const wishlistRef = collection(db, 'users', user.uid, 'wishlist');
  await addDoc(wishlistRef, item);
};

export const subscribeToWishlist = (
  userId: string,
  onChange: (items: ItemDetails[]) => void,
): Unsubscribe => {
  return onSnapshot(collection(db, 'users', userId, 'wishlist'), (col) => {
    onChange(
      col.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        return { id, ...data };
      }) as ItemDetails[],
    );
  });
};

export const deleteItem = async (user: User, itemId: string) => {
  const itemRef = doc(db, 'users', user.uid, 'wishlist', itemId);
  await deleteDoc(itemRef);
};

export const editItem = async (
  user: User,
  itemId: string,
  item: ItemDetails,
) => {
  const itemRef = doc(db, 'users', user.uid, 'wishlist', itemId);
  await updateDoc(itemRef, {
    description: item.description,
    imageUrl: item.imageUrl,
    link: item.link,
    name: item.name,
    price: item.price,
  });
};
