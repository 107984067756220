import { AppBar, Toolbar, IconButton, Typography, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import Profile from './Profile';

interface TopNavProps {
  openDrawer: () => void;
  title: string;
  actionItems?: React.ReactNode;
}

const AppBarOffset = styled('div')(({ theme }) => theme.mixins.toolbar);

const TopNav: React.FunctionComponent<TopNavProps> = ({ openDrawer, title, actionItems }) => {
  return (
    <>
      <AppBar
        position="fixed"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={openDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {actionItems}
          <Profile />
        </Toolbar>
      </AppBar>
      <AppBarOffset />
    </>
  );
};

export default TopNav;
