import { Typography, Box, Grid, TextField, Button, Link } from '@mui/material';
import * as React from 'react';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
} from 'react-hook-form';
import { Link as RouterLink, useHistory} from 'react-router-dom';
import Navigation from '../../common/Navigation/Navigation';
import { signIn } from '../../services/firebase/auth';

interface SignInFormData {
  email: string;
  password: string;
}

interface SignInPageProps {}

const SignInPage: React.FunctionComponent<SignInPageProps> = () => {
  const history = useHistory();

  const { control, handleSubmit } = useForm<SignInFormData>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onValid: SubmitHandler<SignInFormData> = async (data) => {
    try {
      await signIn(data.email, data.password);
      history.push('/');
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const onInvalid: SubmitErrorHandler<SignInFormData> = (data) => {
    throw new Error('It failed');
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      await handleSubmit(onValid, onInvalid)();
    } catch (e) {
      console.log({ e });
    }
  };

  return (
    <>
      <Navigation title="" />
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{ required: 'Email required' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="password"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{ required: 'Password required' }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link component={RouterLink} to="/signup" variant="body2">
              Don't have an account? Sign up
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SignInPage;
