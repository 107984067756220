import { FirebaseError } from '@firebase/util';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut as signOutOfFirebase,
} from 'firebase/auth';
import { initUser } from '../userService';
import { app } from './app';

const auth = getAuth(app);

export const createNewAccount = async (
  email: string,
  password: string,
  displayName: string,
) => {
  const { user } = await createUserWithEmailAndPassword(auth, email, password);
  await initUser(user.uid, displayName);
  await sendVerification();
};

export const sendVerification = async () => {
  if (auth.currentUser != null) {
    await sendEmailVerification(auth.currentUser);
  } else {
    throw new FirebaseError('UNF', 'User not found.');
  }
};

export const signIn = async (email: string, password: string) => {
  await signInWithEmailAndPassword(auth, email, password);
  console.log('Successfully logged in.');
};

export const signOut = async () => {
  await signOutOfFirebase(auth);
};
