import { AccountCircle } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { useAuth } from '../../context/AuthContext';
import { signOut } from '../../services/firebase/auth';
import { Link } from 'react-router-dom';

interface ProfileProps {}

const Profile: React.FunctionComponent<ProfileProps> = () => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleClose();
    signOut();
  };

  if (user == null) {
    return (
      <>
        <Button color="inherit" component={Link} to="/signin">
          Sign In
        </Button>
      </>
    );
  }

  return (
    <>
      {
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {}}>Profile</MenuItem>
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
          </Menu>
        </div>
      }
    </>
  );
};

export default Profile;
