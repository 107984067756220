import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CardHeader,
} from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useAuth } from '../../context/AuthContext';
import ItemDetails from './ItemDetails.model';
import WishItemOptionMenu from './WishItemOptionMenu';

interface WishItemProps {
  itemDetails: ItemDetails;
}

const WishItem: React.FunctionComponent<WishItemProps> = ({ itemDetails }) => {
  const user = useAuth();

  return (
    <>
      <Card sx={{ display: 'flex', flexDirection: 'column' }} raised>
        <CardHeader
          action={!!user.user?.uid ? <WishItemOptionMenu itemId={itemDetails.id} /> : ''}
          title={itemDetails.name}
        />
        <CardMedia
          component="img"
          image={itemDetails.imageUrl}
          alt={itemDetails.name}
          sx={{ maxHeight: 300 }}
        />
        <CardContent sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography sx={{ pr: 1 }} variant="h6" color="primary.main">
              ${itemDetails.price}
            </Typography>
            {itemDetails.link && (
              <Button
                size="small"
                variant="contained"
                component="a"
                href={itemDetails.link}
                target="_blank"
              >
                View
              </Button>
            )}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {itemDetails.description}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default WishItem;
