import * as React from 'react';
import DrawerNav from './DrawerNav';
import TopNav from './TopNav';

interface NavigationProps {
  title: string;
  actionItems?: React.ReactNode;
}

const Navigation: React.FunctionComponent<NavigationProps> = ({ title, actionItems }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return <>
    <TopNav openDrawer={openDrawer} title={title} actionItems={actionItems} />
    <DrawerNav isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} />
  </>;
};

export default Navigation;
