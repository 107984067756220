import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import StarIcon from '@mui/icons-material/Star';
import * as React from 'react';
import { useTheme } from '@emotion/react';

interface DrawerNavProps {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
}

const drawerWidth = 240;
const DrawerNav: React.FunctionComponent<DrawerNavProps> = ({
  isDrawerOpen,
  closeDrawer,
}) => {
  const theme = useTheme() as Theme;
  const activeStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  };

  return (
    <>
      <Drawer
        variant="temporary"
        open={isDrawerOpen}
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          <ListItemButton
            component={NavLink}
            exact
            to="/"
            activeStyle={activeStyle}
          >
            <ListItemIcon>
              <HomeIcon sx={{ '.active &': {
                color: theme.palette.primary.contrastText,
              }}} />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItemButton>
          <ListItemButton
            component={NavLink}
            to="/wishlist"
            activeStyle={activeStyle}
          >
            <ListItemIcon>
              <StarIcon sx={{ '.active &': {
                color: theme.palette.primary.contrastText,
              }}} />
            </ListItemIcon>
            <ListItemText primary={'Wishlist'} />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
};

export default DrawerNav;
