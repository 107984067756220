import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ItemDetails from './ItemDetails.model';
import WishItem from './WishItem';

interface WishCollectionProps {
  items: ItemDetails[];
}

const WishCollection: React.FunctionComponent<WishCollectionProps> = ({
  items,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        {items.map((item) => (
          <Grid key={item.name} item xs={12} sm={6} md={4}>
            <Box>
              <WishItem itemDetails={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default WishCollection;
