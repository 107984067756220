import { Box } from '@mui/system';
import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import SignInPage from './components/SignInPage/SignInPage';
import SignUpPage from './components/SignUpPage/SignUpPage';
import WishlistPage from './components/WishlistPage/WishlistPage';
import CreditPage from './components/CreditPage/CreditPage';

interface AppProps {}

const App: React.FunctionComponent<AppProps> = () => {
  return (
    <>
      <Router>
        <Box sx={{ margin: 'auto', maxWidth: 'md', px: 2, pt: 2 }}>
          <Switch>
            <Route path="/signup">
              <SignUpPage />
            </Route>
            <Route path="/signin">
              <SignInPage />
            </Route>
            <Route path={['/wishlist/:userId', '/wishlist']}>
              <WishlistPage />
            </Route>
            <Route path="/credit">
              <CreditPage />
            </Route>
            <Route exact path="/">
              <HomePage />
            </Route>
          </Switch>
        </Box>
      </Router>
    </>
  );
};

export default App;
