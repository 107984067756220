import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import {
  Controller,
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form';
import ItemDetails from './ItemDetails.model';

interface NewItemFormProps {
  onNewItem: (newItem: ItemDetails) => void;
}

const NewItemForm: React.FunctionComponent<NewItemFormProps> = ({
  onNewItem,
}) => {
  const [open, setOpen] = React.useState(false);
  const { control, handleSubmit, reset } = useForm<ItemDetails>({
    defaultValues: {
      name: '',
      description: '',
      price: '',
      link: '',
      imageUrl: '',
    },
  });

  React.useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onValid: SubmitHandler<ItemDetails> = (data) => {
    onNewItem(data);
    handleClose();
  };

  const onInvalid: SubmitErrorHandler<ItemDetails> = (data) => {
    throw new Error('It failed');
  };

  const onSubmit = async () => {
    try {
      await handleSubmit(onValid, onInvalid)();
      handleClose();
    } catch (e) {
      console.log({ e });
    }
  };

  // TODO: Add stricter form validation

  return (
    <>
      <Fab
        sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 100 }}
        color="secondary"
        aria-label="add new item"
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Item</DialogTitle>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                autoFocus
                margin="dense"
                label="Name"
                fullWidth
                variant="standard"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{ required: 'Name required' }}
          />
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                margin="dense"
                label="Description"
                fullWidth
                variant="standard"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{ required: 'Description required' }}
          />
          <Controller
            name="price"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                margin="dense"
                label="Price"
                fullWidth
                variant="standard"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Price required',
              validate: {
                isNumeric: (val) => !isNaN(+val) || 'Price must be numeric',
              },
            }}
          />
          <Controller
            name="link"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                margin="dense"
                label="Link"
                fullWidth
                variant="standard"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="imageUrl"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                margin="dense"
                label="Image URL"
                fullWidth
                variant="standard"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewItemForm;
