import * as React from 'react';

interface CreditPageProps {}

const CreditPage: React.FunctionComponent<CreditPageProps> = () => {
  return (
    <>
      <h1>Credits and Attribution!</h1>
      <div>
        Icons made by{' '}
        <a href="https://www.freepik.com" title="Freepik">
          Freepik
        </a>{' '}
        from{' '}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </div>
    </>
  );
};

export default CreditPage;
