import * as React from 'react';
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteItem } from '../../services/wishlistService';
import { useAuth } from '../../context/AuthContext';

interface DeleteOptionProps {
  onCloseMenu: () => void;
  itemId: string | undefined;
}

const DeleteOption: React.FunctionComponent<DeleteOptionProps> = ({
  onCloseMenu,
  itemId,
}) => {
  const [open, setOpen] = React.useState(false);
  const user = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if (user.user == null || itemId == null) {
      // I don't think this state should be possible, but just in case. 
      // Should probably add some messaging to the user if it messes up like this.
      return;
    }

    // TODO: This might cause a harmless unmount error due to race condition. 
    // Should probably fix this eventually.
    handleCloseDialog();
    onCloseMenu();
    await deleteItem(user.user, itemId);
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteOption;
