import { Box } from '@mui/system';
import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { subscribeToWishlist, saveItem } from '../../services/wishlistService';
import { getDisplayName } from '../../services/userService';
import ItemDetails from './ItemDetails.model';
import NewItemForm from './NewItemForm';
import WishCollection from './WishCollection';
import { IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareUserButton from './ShareUserButton';
import Navigation from '../../common/Navigation/Navigation';

interface WishlistPageProps {}

const WishlistPage: React.FunctionComponent<WishlistPageProps> = () => {
  const [wishItems, setWishItems] = React.useState<ItemDetails[]>([]);
  const [displayName, setDisplayName] = React.useState<string>('');
  const { user } = useAuth();
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();

  React.useEffect(() => {
    if (user != null && !userId) {
      history.push(`/wishlist/${user.uid}`);
    }
  }, [user?.uid, userId]);

  React.useEffect(() => {
    if (userId != null) {
      const fetch = async () => {
        const name = await getDisplayName(userId);
        setDisplayName(name);
      };
      fetch();
      return subscribeToWishlist(userId, setWishItems);
    }
  }, [userId]);

  const onNewItem = (newItem: ItemDetails) => {
    if (user != null) {
      saveItem(user, newItem);
    }
  };

  return (
    <>
      <Navigation
        title={`Wishlist${displayName && ` - ${displayName}`}`}
        actionItems={
          <>
            <ShareUserButton />
            <IconButton color="inherit" size="medium" aria-label="favorite">
              <FavoriteBorderIcon />
            </IconButton>
          </>
        }
      />
      <Box>
        {!userId && !user?.uid && <>No user found.</>}
        {user?.uid != null && userId === user?.uid && (
          <NewItemForm onNewItem={onNewItem} />
        )}
        <WishCollection items={wishItems} />
      </Box>
    </>
  );
};

export default WishlistPage;
