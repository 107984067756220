import { IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useHistory } from 'react-router';
import Navigation from '../../common/Navigation/Navigation';
import SearchIcon from '@mui/icons-material/Search';

interface HomePageProps {}

const HomePage: React.FunctionComponent<HomePageProps> = () => {
  const history = useHistory();
  const [search, setSearch] = React.useState('');
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onSearch = () => {
    const userId = search.split('wishlist/')[1];
    if (userId) {
      history.push(`/wishlist/${userId}`);
    } else {
      // TODO: Display error
    }
  };

  return (
    <>
      <Navigation title="Home" />
      <Typography component="p">
        Paste a shared link here to go to wishlist:
      </Typography>
      <Box sx={{ mb: 2, display: 'flex' }}>
      <TextField sx={{ mr: 2, width: '100%' }} label="Search" variant="standard" value={search} onChange={handleSearchChange} />
      <IconButton  onClick={onSearch}><SearchIcon /></IconButton>
      </Box>
      <Typography component="p">
        This is the wishlist app. Use it to keep track of items you want to
        share with others when they ask what to get you.
      </Typography>
    </>
  );
};

export default HomePage;
