import {
  doc,
  getDoc,
  setDoc,
} from '@firebase/firestore';
import { db } from './firebase/firestore';

export const initUser = async (userId: string, displayName: string) => {
  await setDoc(doc(db, 'users', userId, 'public', 'details'), { displayName }, { merge: true });
};

export const getDisplayName = async (userId: string) => {
  const docSnap = await getDoc(doc(db, 'users', userId, 'public', 'details'));

  if (docSnap.exists()) {
    return docSnap.data().displayName as string;
  } else {
    console.log('No user found');
  }
  return 'No user found';
};
